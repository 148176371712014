/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  useToast,
  Box,
  Flex,
  Text,
  Select,
  Stack,
  Switch,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
  Divider,
  Icon,
  AspectRatioBox,
} from '@chakra-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import actions from '../actions';
import VideoWall from './VideoWall';
import DrawingCanvas from '../DrawingCanvas';
import { showErrorToast, showSuccessToast } from '../utils';

const Dashboard = ({ zipClass }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideNotes, setCurrentSlideNotes] = useState(null);
  const [totalSlidesCount, setTotalSlidesCount] = useState(0);
  const [drawingMode, setDrawingMode] = useState(false);
  const [canvasColor, setCanavasColor] = useState('#000');
  const [canvasData, setCanvasData] = useState(null);
  const [alertIssues, setAlertIssues] = useState([]);
  const [studentsWithIssues, setStudentsWithIssues] = useState([]);
  const [sendingRedAlert, setSendingRedAlert] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const history = useHistory();
  const toast = useToast();
  const { slot_id: slotId } = useParams();

  // References
  const roomRef = useRef(null);
  const canvasRef = useRef(null);
  const currentSlideRef = useRef(null);
  const nextSlideRef = useRef(null);
  const nextButtonRef = useRef(null);

  // Actions
  const clearCanvasAction = () => {
    if (canvasRef && canvasRef.current) {
      canvasRef.current.clear();
      setCanvasData(null);
    }

    if (roomRef && roomRef.current) {
      if (roomRef.current.localParticipant) {
        const [localDataTrackPublication] = [
          ...roomRef.current.localParticipant.dataTracks.values(),
        ];
        if (localDataTrackPublication) {
          localDataTrackPublication.track.send(
            JSON.stringify({
              action: 'draw',
              drawMetadata: {
                clear: true,
              },
            }),
          );
        }
      }
    }
  };

  const toggleDrawingMode = event => {
    setDrawingMode(event);
  };

  const sendRedAlert = async closeMenu => {
    try {
      setSendingRedAlert(true);
      await actions.sendTeacherRedAlert(slotId, {
        teacherName: zipClass.teacherName,
        classTitle: zipClass.classTitle,
        students: studentsWithIssues,
        issues: alertIssues,
      });

      showSuccessToast(toast, '🚀 ZipSchool Notified!');
    } catch (err) {
      showErrorToast(toast, 'Error notifying ZipSchool. Please use Slack.');
      console.warn(err);
    }

    setSendingRedAlert(false);
    closeMenu();
    setStudentsWithIssues([]);
    setAlertIssues([]);
  };

  const onEndRoom = async () => {
    setLoading(true);
    const { err } = await actions.endClassroom(slotId);

    if (err) {
      showErrorToast(toast, err.message);
    } else {
      history.push(encodeURI(`/rooms/${slotId}/feedback`));
    }
  };

  const setRoomRef = room => {
    roomRef.current = room;
  };

  const onRevealAnswer = () => {
    setCurrentQuestion(prevQuestion => {
      return { ...prevQuestion, showAnswer: true };
    });
  };

  const onNextSlide = () => {
    setCurrentSlide(currentSlideState => {
      if (currentSlideState === totalSlidesCount - 1) return currentSlideState;
      return currentSlideState + 1;
    });

    // If there is any drawing data, remove it here
    clearCanvasAction();
    setCanvasData(null);
  };

  const onPreviousSlide = () => {
    setCurrentSlide(currentSlideState => {
      if (currentSlideState === 0) return currentSlideState;
      return currentSlideState - 1;
    });

    // If there is any drawing data, remove it here
    clearCanvasAction();
    setCanvasData(null);
  };

  const oniFrameLoad = () => {
    if (currentSlideRef && currentSlideRef.current && currentSlideRef.current.contentWindow) {
      currentSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'getTotalSlides' }),
        '*',
      );

      currentSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'getSlideNotes' }),
        '*',
      );

      nextSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'slide', args: [1, 1] }),
        '*',
      );
    }

    if (nextButtonRef && nextButtonRef.current) {
      nextButtonRef.current.focus();
    }
  };

  const handleKeyDownEvent = e => {
    if (e && e.keyCode === 39) {
      onNextSlide();
    } else if (e && e.keyCode === 37) {
      onPreviousSlide();
    }
  };

  // Render Methods
  const renderIssuesList = () => (
    <MenuOptionGroup
      title="What's the issue?"
      type="checkbox"
      onChange={value => setAlertIssues(value.filter(val => !!val))}
    >
      <MenuItemOption value="student_audio_issue" key="student_audio_issue">
        🔇 Student Audio Issues
      </MenuItemOption>
      <MenuItemOption value="student_video_issues" key="student_video_issues">
        📹 Student Video Issues
      </MenuItemOption>
      <MenuItemOption value="teacher_issues" key="teacher_issues">
        🍎 Teacher Video/Audio Issues
      </MenuItemOption>
      <MenuItemOption value="other_issues" key="other_issues">
        ❗️ Other Issues
      </MenuItemOption>
    </MenuOptionGroup>
  );

  const renderStudentAlertList = () =>
    zipClass &&
    zipClass.students && (
      <MenuOptionGroup
        title="Students"
        type="checkbox"
        onChange={value => setStudentsWithIssues(value.filter(val => !!val))}
      >
        {zipClass.students.map(student => (
          <MenuItemOption value={student.name} key={student.id}>
            {student.name}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    );

  const renderRedAlertMenu = () => (
    <Flex justifyContent="center" alignItems="center" zIndex="50">
      <Menu closeOnSelect={false}>
        {({ onClose }) => (
          <>
            <MenuButton fontSize="sm" as={Button} rightIcon="chevron-down" bg="red.200">
              <Text fontWeight="bold">🚨 Alert ZipSchool</Text>
            </MenuButton>
            <MenuList>
              {renderIssuesList()}
              <MenuDivider />
              {renderStudentAlertList()}
              <Flex justifyContent="center" p={4}>
                <MenuItem
                  as={Button}
                  w="100%"
                  backgroundColor="purple.400"
                  color="white"
                  p={3}
                  onClick={() => {
                    sendRedAlert(onClose);
                  }}
                  isLoading={sendingRedAlert}
                  isDisabled={!alertIssues.length}
                >
                  Send Alert
                </MenuItem>
              </Flex>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );

  const renderDrawingModeToggle = () => (
    <Flex bg="purple.400" p={2} justifyContent="space-between" zIndex="100">
      <Flex justifyContent="space-between" width={drawingMode ? '50%' : '100%'}>
        <Flex alignItems="center">
          <Text
            style={{ whiteSpace: 'pre-line' }}
            color="white"
            pr={3}
            fontSize="sm"
            fontWeight="bold"
          >
            {drawingMode ? 'Drawing\nMode 🎨' : 'Drawing\nMode'}
          </Text>
          <Switch
            size="md"
            color="green"
            pt={1}
            onChange={e => toggleDrawingMode(e.target.checked)}
          />
        </Flex>
        {!drawingMode && renderRedAlertMenu()}
      </Flex>
      {drawingMode && (
        <Stack isInline spacing={5}>
          <Select
            name="canvas_color"
            variant="filled"
            cursor="pointer"
            defaultValue={canvasColor}
            onChange={e => setCanavasColor(e.target.value)}
          >
            <option key="#000" value="#000">
              ⚫️ Black
            </option>
            <option key="#fff" value="#fff">
              ⚪️ White
            </option>
            <option key="#d14" value="#d14">
              🔴 Red
            </option>
          </Select>
          <Button
            w="75%"
            h="40px"
            bg="blue.400"
            onClick={clearCanvasAction}
            color="white"
            fontWeight="bold"
            shadow="md"
          >
            <Text>✨ Erase All</Text>
          </Button>
        </Stack>
      )}
    </Flex>
  );

  const renderNotes = () => (
    <Box style={{ overflowY: 'scroll' }} pl={5}>
      <ReactMarkdown>{currentSlideNotes}</ReactMarkdown>
    </Box>
  );

  const renderNextButton = () => {
    const { poll } = zipClass.triggers[currentSlide.toString()] || {};
    if (poll && !(currentQuestion && currentQuestion.showAnswer)) {
      return (
        <Button
          ref={nextButtonRef}
          isDisabled={currentSlide === totalSlidesCount - 1}
          w="100%"
          h="100%"
          color="white"
          bg="green.500"
          fontWeight="bold"
          fontSize="md"
          alignItems="center"
          _focus={{ outline: 'none' }}
          _hover={{ bg: 'green.500' }}
          onClick={onRevealAnswer}
          onKeyDown={handleKeyDownEvent}
        >
          Reveal Answer
        </Button>
      );
    }
    return (
      <Button
        ref={nextButtonRef}
        isDisabled={currentSlide === totalSlidesCount - 1}
        w="100%"
        h="100%"
        color="white"
        bg="purple.500"
        fontWeight="bold"
        fontSize="md"
        alignItems="center"
        _focus={{ outline: 'none' }}
        _hover={{ bg: 'purple.500' }}
        onClick={onNextSlide}
        onKeyDown={handleKeyDownEvent}
      >
        Next <Icon fontSize="2xl" name="chevron-right" />
      </Button>
    );
  };

  const renderSlideControlButtons = () => (
    <Box h="100px" p={3}>
      <Flex h="100%" alignItems="center" justifyContent="space-between">
        <Box w="45%" h="100%">
          <Button
            isDisabled={currentSlide === 0}
            w="100%"
            h="100%"
            color="white"
            bg="purple.500"
            fontWeight="bold"
            alignItems="center"
            fontSize="md"
            _focus={{ outline: 'none' }}
            _hover={{ bg: 'purple.500' }}
            onClick={onPreviousSlide}
          >
            <Icon fontSize="2xl" name="chevron-left" /> Previous
          </Button>
        </Box>
        <Box w="45%" h="100%">
          {renderNextButton()}
        </Box>
      </Flex>
    </Box>
  );

  const renderHasQuiz = (slide, mode) => {
    const trigger = zipClass.triggers[slide.toString()];
    if (!trigger || !trigger.poll) return null;

    return (
      <Box position="absolute" p={3} bg="rgba(255,255,255,0.75)" right="0" left="0" bottom="0">
        {mode === 'preview' && (
          <Text fontSize="sm" fontWeight="bold" textAlign="center">
            ✍️ QUESTION ✍️
          </Text>
        )}
        {mode !== 'preview' && (
          <Box color="black">
            {currentQuestion &&
              currentQuestion.answers.map(a => (
                <Text
                  key={a.title}
                  color={a.isCorrect && currentQuestion.showAnswer ? 'green.700' : 'black'}
                  fontSize="lg"
                >
                  {a.title}
                  {a.isCorrect && currentQuestion.showAnswer && (
                    <span style={{ marginLeft: '5px' }}>✅</span>
                  )}
                </Text>
              ))}
          </Box>
        )}
      </Box>
    );
  };

  const renderHasGame = (slide, mode) => {
    const trigger = zipClass.triggers[slide.toString()];
    if (!trigger || !trigger.game) return null;

    return (
      <Box style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }} bg="purple.500">
        {mode === 'preview' && (
          <Box position="absolute" p={3} bg="rgba(255,255,255,0.75)" right="0" left="0" bottom="0">
            <Text fontSize="sm" fontWeight="bold" textAlign="center">
              🕹 Game 🕹️
            </Text>
          </Box>
        )}
        <iframe
          title="GamePreview"
          src={trigger.game.url}
          width="100%"
          height="100%"
          style={{ zIndex: 10 }}
        />
      </Box>
    );
  };

  const renderNextSlide = () => (
    <AspectRatioBox ratio={[16 / 9]}>
      <Box h="100%" position="relative">
        <iframe
          ref={nextSlideRef}
          title="Presentation"
          style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
          width="100%"
          height="100%"
          src={zipClass.presentation_url}
        />
        {renderHasQuiz(currentSlide + 1, 'preview')}
        {renderHasGame(currentSlide + 1, 'preview')}
      </Box>
    </AspectRatioBox>
  );

  /* const renderResource = resource => {
    const { title, value, date, id, type } = resource;
    return (
      <Box key={id} h="100%" bg="gray.100" borderRadius="4px" p={2}>
        <Text fontWeight="medium">{title}</Text>
        <Text>
          {type === 'link' ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          ) : (
            value
          )}
        </Text>
        <Text fontSize="xs">{date}</Text>
      </Box>
    );
  }; */

  /* const renderSlideCount = () => (
    <Box p={1}>
      <Text textAlign="center" fontSize="sm">
        {`Slide ${currentSlide + 1}/${totalSlidesCount}`}
      </Text>
    </Box>
  ); */

  // UseEffects
  useEffect(() => {
    if (currentSlideRef && currentSlideRef.current && currentSlideRef.current.contentWindow) {
      currentSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'slide', args: [currentSlide, currentSlide] }),
        '*',
      );

      nextSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'slide', args: [currentSlide + 1, currentSlide + 1] }),
        '*',
      );

      currentSlideRef.current.contentWindow.postMessage(
        JSON.stringify({ method: 'getSlideNotes' }),
        '*',
      );
    }
  }, [currentSlide]);

  useEffect(() => {
    window.addEventListener('message', event => {
      try {
        const { namespace, eventName, method, result } = JSON.parse(event.data);
        if (namespace === 'reveal' && eventName === 'callback' && method === 'getSlideNotes') {
          setCurrentSlideNotes(result);
        } else if (
          namespace === 'reveal' &&
          eventName === 'callback' &&
          method === 'getTotalSlides'
        ) {
          if (parseInt(result, 10)) setTotalSlidesCount(parseInt(result, 10));
        }
      } catch (err) {
        if (err.name !== 'SyntaxError') {
          console.warn(err);
        }
      }
    });

    if (currentSlideRef && currentSlideRef.current) currentSlideRef.current.focus();
  }, [currentSlideRef]);

  return (
    <Box d="flex" w="100%" h="100vh" onKeyDown={handleKeyDownEvent} borderColor="gray.100">
      {zipClass && (
        <>
          <Box
            className="no-scroll-bar"
            w={drawingMode ? '100%' : '30%'}
            overflow="scroll"
            borderLeftWidth="3px"
            borderLeftColor="purple.400"
            borderBottomWidth="3px"
            borderBottomColor="purple.400"
          >
            {renderDrawingModeToggle()}

            {zipClass.presentation_url && (
              <>
                <Box w="100%">
                  <Box>
                    <Text textAlign="center" fontSize="sm" fontWeight="bold">
                      {`Current Slide (${currentSlide + 1}/${totalSlidesCount})`}
                    </Text>
                  </Box>
                  <AspectRatioBox ratio={[16 / 9]}>
                    <Box position="relative">
                      {drawingMode && (
                        <DrawingCanvas
                          width="100%"
                          height="100%"
                          roomRef={roomRef}
                          canvasRef={canvasRef}
                          canvasColor={canvasColor}
                          canvasData={canvasData}
                          setCanvasData={setCanvasData}
                        />
                      )}
                      <iframe
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                        }}
                        ref={currentSlideRef}
                        onLoad={oniFrameLoad}
                        title="Presentation"
                        width="100%"
                        height="100%"
                        src={zipClass.presentation_url}
                      />
                      {renderHasGame(currentSlide)}
                      {renderHasQuiz(currentSlide)}
                    </Box>
                  </AspectRatioBox>
                </Box>
              </>
            )}
            <Box d={drawingMode ? 'none' : 'block'}>
              <Box p={3}>
                <Flex justifyContent="center">
                  <Box w="50%" pl={1} h="10%" rounded="lg" justifyItems="center">
                    <Text fontSize="sm" textAlign="center" fontWeight="bold">
                      Next Slide
                    </Text>
                    {renderNextSlide()}
                  </Box>
                </Flex>
              </Box>
              <Divider />
              {renderSlideControlButtons()}
              <Divider />
              {renderNotes()}
            </Box>
          </Box>
          <VideoWall
            loading={loading}
            onEndRoom={onEndRoom}
            token={zipClass.token}
            teacherName={zipClass.teacherName}
            roomId={zipClass.room_id}
            students={zipClass.students}
            startAt={zipClass.startAt}
            duration={zipClass.duration}
            questions={zipClass.questions}
            triggers={zipClass.triggers}
            slotId={slotId}
            setRoomRef={setRoomRef}
            drawingMode={drawingMode}
            currentSlide={currentSlide}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </>
      )}

      {loading && !zipClass && <Text>Loading the room...</Text>}
    </Box>
  );
};

export default Dashboard;
